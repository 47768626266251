header {
  height: 60vh;
  padding-top: 5rem;
  overflow: hidden;
}
h1 {
  color: var(--color-primary);
}
.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* CTA */
.cta {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* HEADER SOCIALS */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.8rem;
  position: absolute;
  left: 0;
  bottom: 9rem;
}
/* SCROLL DOWN */
.contact {
  position: absolute;
  right: -2.3rem;
  bottom: 12.4rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
  letter-spacing: 0.2rem;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
/* @media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
} */

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
  header {
    padding-top: 2rem;
    height: 60vh;
  }
  iframe {
    width:95%
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
}
