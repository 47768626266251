/* Importing google font  */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap');

.text-sphere {
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
}

.tagcloud {
    display: inline-block;
    top: 0;
    left: 0;
    /* font-family: 'Courier', sans-serif; */
    font-weight: 600;
    letter-spacing: 0.0625em;
    font-size: 1.2em;

    position: relative;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
}

/* Change color of each text in sphere on hover   */
.tagcloud--item {
    color: var(--color-light);
    /* text-transform: uppercase; */
}

.tagcloud--item:hover {
    color: var(--color-primary);
}
